<template lang="pug">
    .m-auto.max-w-1250.bg-white-pure.l-box-shadow.rounded.py-8.px-10.text-left
        .nps-container(v-if="!loading")
            //LogoTitleUltimate
            div(class="flex items-center justify-left mt-24 sm:flex-col md:flex-row")
                .text-black.font-bold
                    | Thank you. Your 
                    span.text-orange feedback matters.
                div(class="ml-6 mt-4 md:mt-0")
                    v-btn(
                        depressed
                        class="w-100"
                        @click="goBack()"
                    )
                        | {{cancelBtnText}}
            div(class="text-black mt-6 text-center md:text-left")
                | All information you provide is anonymous and confidential. For more details please see our
                span.link-text
                    a(:href="privacyPolicyLink" )
                        | privacy policy
                |.
        .text-black.mt-4.text-center.mb-8(v-else)
            Loader.w-12.h-12

</template>

<script>
import Loader from '@/assets/loader.svg'
import {computed, onMounted, onUnmounted, ref} from '@vue/composition-api'
import {
    DEFAULT_SHORTNAME,
    NPS_COMPLETE_SURVEY_TEXT, NPS_SURVEY_NO_PHONE_TEXT, NPS_SURVEY_TEXT,
} from '@/modules/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {useTimeout} from '@/composables/thank-you'
import LogoTitleUltimate from '@/components/basic/LogoTitleUltimate'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'NpsThankYou',
    components: {LogoTitleUltimate, Loader},
    props: {
        category: {
            type: String,
            default: ''
        },
        hasPhoneOrEmail: {
            type: Boolean,
            default: false
        },
        isFullSurvey: {
            type: Boolean,
            default: false
        },
        tenantName: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root }) {
        const route = root.$route
        const shortNameRoute = ref(!!route.params.shortName)
        const showPhoneAndEmail = ref(!!route.query.spe)
        const routeType = ref(route.params?.routeType || '')
        const completeSurvey = ref({ email: '', mobile: { phone: '' } })
        const formAnswerId = ref(route.params?.formAnswerId)
        // const tenantId = ref(route.query?.tenant || route.query?.ti)
        const variables = ref({})
        const showAlert = ref(false)
        const message = ref('')
        const sendingEmail = ref(false)
        const alertType = ref('success')
        const showGoBackLoader = ref(false)
        const privacyPolicyLink = ref('https://www.ultimateq.com.au/privacy-policy')

        const completeSurveyTitle = ref('Can we help you further?')
        const completeSurveyText = ref(NPS_COMPLETE_SURVEY_TEXT)
        const {
            thankYouRedirectQueryParams,
            previousRoute: previous
        } = useGetters(['thankYouRedirectQueryParams', 'previousRoute'])
        const {
            isKiosk,
            previousRoute,
            params,
            shortName,
            router,
            completeSurveyForm,
            counter,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
        } = useTimeout(variables)
        previousRoute.value = previous.value || `${routeType.value}-view`
        if (isKiosk.value) previousRoute.value = `Kiosk-${previousRoute.value}`

        isKiosk.value = !!route.params.kiosk
        params.value = thankYouRedirectQueryParams.value
        shortName.value = shortNameRoute.value
        router.value = root.$router

        //#region functions
        const goBack = () => {
            showGoBackLoader.value = true
            //TODO: add this back after conference
            callBackToPreSurvey()
            showGoBackLoader.value = false

            // //TODO: remove below after conference
            // goToUrl('https://www.ultimateq.health/enquiry')
        }
        //#endregion

        //#region computed properties
        const cancelBtnText = computed(() => {
            return isKiosk.value ? `Go back ` + (counter.value ? `(${counter.value})` : '')  : 'Go back'
        })

        const thankYouTitle = computed(() => {
            if (!showPhoneAndEmail.value) return 'Thank you for your time.'
            return 'Can you tell us more?'
        })

        const thankYouText = computed(() => {
            if (showPhoneAndEmail.value) return NPS_SURVEY_TEXT
            return NPS_SURVEY_NO_PHONE_TEXT
        })
        //#endregion

        if (isKiosk.value) runTimeout()

        //#region life cycle hooks
        onMounted(async () => {
            // if we don't have a formAnswerId, we redirect back to the start
            if (!formAnswerId.value) {
                let useShortName = true
                // if we don't have the previous route, let's go back to the /feedback form
                let route = {name: previousRoute.value}

                //TODO: remove after conference
                let routeName = 'nps-feedback'
                if (isKiosk.value) routeName = `Kiosk-${routeName}`
                shortName.value = DEFAULT_SHORTNAME
                if (useShortName) route = { name: routeName, params: { shortName: shortName.value } }

                await router.value.push(route)
            }
        })

        onUnmounted(() => {
            // make sure we get rid of the timeout if we come back through this page (browser back button pressed)
            cancelTimeout()
        })
        //#endregion

        return {
            thankYouText,
            isKiosk,
            showPhoneAndEmail,
            completeSurvey,
            completeSurveyForm,
            showAlert,
            message,
            cancelBtnText,
            alertType,
            sendingEmail,
            counter,
            completeSurveyTitle,
            completeSurveyText,
            thankYouTitle,
            showGoBackLoader,
            privacyPolicyLink,
            goBack,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
        }
    }
}
</script>
