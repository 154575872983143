<template>
    <div class="p-8">
        <h1>hello from sidebar</h1>
        {{element}}
    </div>
</template>

<script>
export default {
    name: 'SidebarContent',
    props: {
        element: {
            type: Object,
            default: () => {}
        }
    }
}
</script>
