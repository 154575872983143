<template>
    <div class="h-full">
        <GlobalHeader :title="title" />
        <div class="container m-auto my-3">
            <Form />
        </div>
        <Sidebar />
    </div>
</template>

<script>
import GlobalHeader from '@/components/shared/GlobalHeader'
import Sidebar from '@/components/admin/Sidebar'
import Form from '@/components/forms/Form'
import {mapActions} from 'vuex'


export default {
    name: 'AdminView',
    components: { GlobalHeader, Sidebar, Form },
    data: () => ({
        title: 'Editing Patient form',
        element: {},
        index: null
    }),
    mounted() {
        this.setEditingForm(true)
        this.$root.$on('edit-element', this.setElement)
    },
    methods: {
        ...mapActions(['setEditingForm', 'setElementToEdit']),
        setElement(obj) {
            this.element = obj?.element || {}
            this.index = obj?.index
            this.setElementToEdit({ element: this.element, index: this.index})
        }
    },
    destroyed() {
        this.$root.$off('edit-element')
    }
}
</script>
