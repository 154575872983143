<template lang="pug">
    .w-full.rounded.p-4(
        :class="{'l-box-shadow bg-white-pure': !isQpa}"
    )
        .max-w-screen-sm.m-auto.justify-center(v-if="!loading")
            LogoTitle(v-if="isQpa")
            h2(
                class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
                :class="{'text-white': isQpa}"
            )
                <!--inside '' is category text-->
                <!--Thank you for your feedback on 'My Appointment'-->
                | Thank you for your feedback!
                div(
                    v-if="hasReferrer"
                )
                    | You can close this browser now.
            //div(class="text-center text-sm lg:text-lg max-w-3/4 m-auto mt-2") Can you tell us more?
            //img(
            //    src="@/assets/images/thankyou_survey.png"
            //    alt="Thank you" class="w-full max-w-400 m-auto mt-8"
            //)
            .text-black.mt-4.text-center.mb-8(class="max-w-1/2 m-auto" v-if="!isQpa || hasReferrer")
                | {{ thankYouText }}
            .mt-4.text-center.mb-8(
                v-if="showPhoneAndEmail && isKiosk"
            )
                CompleteFullSurvey(
                    v-if="showPhoneAndEmail"
                    :is-kiosk="isKiosk"
                    v-model="completeSurvey"
                    @fieldFocus="fieldResetTimeout"
                )
            .flex.flex-row.justify-end.mt-8(v-if="showAlert")
                v-alert(
                    :closable="false"
                    :type="alertType"
                    class="text-sm w-full"
                ) {{ message }}
            .flex.flex-row.justify-center.mt-8(v-if="(isKiosk || isQpa) && !hasReferrer")
                v-btn(
                    v-if="showPhoneAndEmail"
                    depressed
                    class="w-130 mx-2"
                    @click="callBackToPreSurvey"
                )
                    fa-icon(v-if="sendingEmail" icon="spinner" spin class="mr-2 text-white-pure")
                    | {{cancelBtnText}}
                    fa-icon(v-if="!sendingEmail" icon="chevron-right" class="ml-2 text-white-pure pl-1")
                v-btn(
                    v-if="!finished"
                    depressed
                    class="success w-130"
                    @click.prevent="doSubmit"
                )
                    | {{btnText}}
                    fa-icon(icon="chevron-right" class="ml-2 text-white-pure")
        .max-w-screen-sm.m-auto.justify-center(v-else)
            Loader.w-12.h-12
</template>

<script>
import Loader from '@/assets/loader.svg'
import {computed, onMounted, onUnmounted, ref, watch, watchEffect} from '@vue/composition-api'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import {
    LONG_SURVEY_TEXT, NPS_REFERRER_TEXT, NPS_SURVEY_TEXT,
    SHORT_SURVEY_NEITHER_TEXT,
    SHORT_SURVEY_PHONE_OR_EMAIL_TEXT, UCC_FEEDBACK_TEXT
} from '@/modules/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import {useTimeout} from '@/composables/thank-you'
import LogoTitle from '@/components/basic/LogoTitle'

const { useGetters } = createNamespacedHelpers('')


export default {
    name: 'ThankYou',
    components: {Loader, CompleteFullSurvey, LogoTitle},
    props: {
        category: {
            type: String,
            default: ''
        },
        hasPhoneOrEmail: {
            type: Boolean,
            default: false
        },
        isFullSurvey: {
            type: Boolean,
            default: false
        },
        tenantName: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root }) {
        const route = root.$route
        const category = ref(props.category)
        const hasPhoneOrEmail = ref(props.hasPhoneOrEmail)
        const thankYouText = ref('')
        const tenantName = ref(props.tenantName)
        const isFullSurvey = ref(props.isFullSurvey)
        const shortNameRoute = ref(!!route.params.shortName)
        const showPhoneAndEmail = ref(!!route.query.spe)
        const routeType = ref(route.params?.routeType || '')
        const completeSurvey = ref({ email: '', mobile: { phone: '' } })
        const formAnswerId = ref(route.params?.formAnswerId)
        const hasReferrer = ref(route.query?.referrer)
        const variables = ref({})
        const showAlert = ref(false)
        const message = ref('')
        const finished = ref(false)
        const sendingEmail = ref(false)
        const alertType = ref('success')
        const {
            previousRoute: previous,
            tenant
        } = useGetters([
            'previousRoute',
            'tenant'
        ])
        const tenantStylesStore = useTenantStylesStore()
        const { showCloseBrowserMessage } = storeToRefs(tenantStylesStore)

        const {
            isKiosk,
            previousRoute,
            params,
            shortName,
            router,
            completeSurveyForm,
            counter,
            formSetId,
            tenantId,
            sendSurvey,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            redirectTo
        } = useTimeout(variables)

        tenantId.value = route.query?.tenant || route.query?.ti
        formSetId.value = route.query?.fsi
        previousRoute.value = previous.value || `Kiosk-${routeType.value}-view`
        if (!isKiosk.value) isKiosk.value = !!route.params.kiosk
        params.value = route.query
        shortName.value = shortNameRoute.value
        router.value = root.$router

        //#region functions
        const doSubmit = async () => {
            cancelTimeout()
            if (!completeSurvey.value?.email && !completeSurvey.value?.mobile?.phone) {
                await callBackToPreSurvey()
            } else {
                try {
                    sendingEmail.value = true
                    await sendSurvey()
                    alertType.value = 'success'
                    let phoneAndEmailText = `${completeSurvey.value.email || ''}`
                    if (phoneAndEmailText && completeSurvey.value?.mobile?.phone) {
                        phoneAndEmailText += ' and '
                    }
                    phoneAndEmailText += completeSurvey.value?.mobile?.phone || ''
                    message.value = phoneAndEmailText ? `Survey sent successfully to ${phoneAndEmailText}` : ''
                    finished.value = true
                } catch (e) {
                    console.log('error', e)
                    alertType.value = 'error'
                    message.value = `Error sending survey to ${completeSurvey.value.email}`
                } finally {
                    if (message.value) showAlert.value = true
                    sendingEmail.value = false
                    runTimeout()
                }
            }
        }
        //#endregion

        //#region computed properties
        const feedbackText = computed(() => {
            return category.value ? `on ${category.value}` : ''
        })

        const btnText = computed(() => {
            return isKiosk.value ? sendingEmail.value ?
                'Please wait...' :
                (completeSurvey.value.email || completeSurvey.value.mobile.phone) ?
                    `Send ` + (counter.value ? `(${counter.value})` : '') :
                    `Finish ` + (counter.value ? `(${counter.value})` : '') :
                'Finish'
        })

        const cancelBtnText = computed(() => {
            return finished.value ? `Finish ` + (counter.value ? `(${counter.value})` : '')  : 'Cancel'
        })

        const isQpa = computed(() => {
            return tenant.value?.parentTenant?.name?.toLowerCase().indexOf('qpa') > -1
        })
        //#endregion

        if (isKiosk.value) runTimeout()

        watch(() => props.tenantName, (newValue) => {
            tenantName.value = newValue
            if (showCloseBrowserMessage.value) thankYouText.value = UCC_FEEDBACK_TEXT
            else if (hasReferrer.value) thankYouText.value = NPS_REFERRER_TEXT
            else if (isFullSurvey.value) thankYouText.value = LONG_SURVEY_TEXT
            else if (isKiosk.value) thankYouText.value = NPS_SURVEY_TEXT
            else if (!props.tenantName) thankYouText.value = ''
            else thankYouText.value = hasPhoneOrEmail.value ? SHORT_SURVEY_PHONE_OR_EMAIL_TEXT : SHORT_SURVEY_NEITHER_TEXT.replace('<TENANT_NAME>', tenantName.value)
        })

        watchEffect(() => {
            variables.value = {
                email: completeSurvey.value.email,
                phone: completeSurvey.value.mobile?.phone,
                formAnswerId: formAnswerId.value,
                tenantId: tenantId.value
            }
        })

        //#region life cycle hooks
        onMounted(async () => {
            // if we don't have a formAnswerId, we redirect back to the start
            if (!formAnswerId.value) await router.value.push({name: previousRoute.value})
            redirectTo.value = route?.query?.redirectTo
        })

        onUnmounted(() => {
            // make sure we get rid of the timeout if we come back through this page (browser back button pressed)
            cancelTimeout()
        })
        //#endregion

        return {
            feedbackText,
            thankYouText,
            isKiosk,
            showPhoneAndEmail,
            completeSurvey,
            completeSurveyForm,
            btnText,
            showAlert,
            message,
            finished,
            cancelBtnText,
            alertType,
            sendingEmail,
            counter,
            isQpa,
            hasReferrer,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            showCloseBrowserMessage,
            doSubmit,
        }
    }
}
</script>

