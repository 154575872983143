<template lang="pug">
    div(class="container m-auto my-3"  :class="{'p-0 m-0': isExternal}")
        SwitchThankYouLayout(
            :category="category"
            :tenant-name="tenantName"
            :has-phone-or-email="hasPhoneOrEmail"
            :is-full-survey="isFullSurvey"
            :loading="tenantLoading"
            :routeType="routeType"
        )
</template>

<script>
import SwitchThankYouLayout from '@/components/thank-you/SwitchThankYouLayout'
import {onMounted, ref} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import GET_CATEGORIES from '@/graphql/queries/getCategories.gql'
import {useQuery} from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import {GET_TENANT} from '@/graphql/queries/tenantQueries.gql'
import store from '@/store'

const { useActions } = createNamespacedHelpers('')

export default {
    name: 'ThankYouView',
    components: {SwitchThankYouLayout},
    setup(_, { root }) {
        const route = root.$route
        const query = route?.query
        const params = route?.params
        const category = ref('')
        const categoryQuery = ref(query?.c)
        const tenantId = ref(query?.tenant || query?.ti)
        const title = ref(query?.t || 'Patient Feedback Survey')
        const isExternal = ref(query.ext)
        const hasPhoneOrEmail = ref(false)
        const isFullSurvey = ref(!!query.fs)
        const formSetId = ref(query.fsi)
        const routeType = ref(params?.routeType)
        const { setFormTitle, setThankYouRedirectQueryParams } = useActions(['setFormTitle', 'setThankYouRedirectQueryParams'])
        const categoryList = ref([])
        const tenantName = ref('')

        if (query.hpe === true || query.hpe === 'true') hasPhoneOrEmail.value = true

        const { onResult, onError } = useQuery(GET_CATEGORIES, {tenantId})

        onResult(({data: {categories}}) => {
            categoryList.value = camelcaseKeys(categories, {deep: true})
            category.value = categoryList.value.find(c => c.categoryId === +categoryQuery.value)?.name
        })

        onError((error) => {
            console.log('error happened', error)
        })

        const { onResult: tenantOnResult, onError: tenantOnError, loading: tenantLoading } = useQuery(GET_TENANT, {tenantId})

        tenantOnResult(({data: {tenant}}) => {
            const name = camelcaseKeys(tenant, {deep: true})?.name
            tenantName.value = name

            setThankYouRedirectQueryParams({
                fsi: formSetId.value,
                tenant: tenantId.value,
                practiceName: name
            })
        })

        tenantOnError((error) => {
            console.log('Error retrieving tenant', error)
        })

        setFormTitle(title.value)

        onMounted(() => {
            store.commit('SET_FORM_PAGE_HEADER_TYPE', route?.meta?.headerType)
        })

        return {
            title,
            isExternal,
            category,
            tenantName,
            hasPhoneOrEmail,
            isFullSurvey,
            tenantLoading,
            routeType
        }
    }
}
</script>
