<template>
    <div class="container m-auto my-3" :class="{'p-0 m-0': isExternal}">
        <SwitchFormLayout :title="title" />
    </div>
</template>

<script>
import SwitchFormLayout from '@/components/forms/SwitchFormLayout'
import {onMounted, ref} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'FormView',
    components: { SwitchFormLayout },
    setup(_, { root }) {
        const { formTitle } = useGetters(['formTitle'])
        const { setFormTitle, setThankYouRedirectQueryParams } = useActions(['setFormTitle', 'setThankYouRedirectQueryParams'])
        const route = root.$route
        const query = route?.query
        const params = route?.params
        const tenantId = ref(query?.tenant || query?.ti)
        const title = ref(formTitle || query?.t || 'Patient Survey')
        const isExternal = ref(query.ext)
        const formSetId = ref(query.fsi || params.fsi)

        onMounted(() => {
            setFormTitle(title.value)
            setThankYouRedirectQueryParams({
                fsi: formSetId.value,
                tenant: tenantId.value,
                practiceName: name
            })
        })

        return {
            title,
            isExternal
        }
    }
}
</script>
