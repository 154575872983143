<template>
    <v-form
        v-model="isValid"
        ref="form"
        class="survey-form"
        @submit.prevent
    >
    <!--Survey Desktop HTML -->
    <div class="m-auto max-w-1250 bg-white-pure l-box-shadow rounded py-8 px-10 text-left">
        <h2
            class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
        >
            <!--category text-->
            My Appointment
        </h2>
        <div class="text-grey-2 mt-2 text-center mb-8">Please rate each statement.</div>
        <div class="w-full grid grid-cols-4 mb-10 items-center">
            <div class="col-span-2">
                <span class="text-base">
                    I was able to make an appointment for a time that suited me
                </span>
            </div>
            <div class="col-span-2">
                <v-btn-toggle
                    borderless
                    :ripple="false"
                    class="face-scale">
                  <v-btn>
                    <Face1Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face2Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face3Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face4Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face5Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <FaceNaSvg class="fill-current" />
                  </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <div class="w-full grid grid-cols-4 mb-10 items-center">
            <div class="col-span-2">
                <span class="text-base">
                    I was able to see the doctor of my choice
                </span>
            </div>
            <div class="col-span-2">
                <v-btn-toggle
                    borderless
                    :ripple="false"
                    class="face-scale">
                  <v-btn>
                    <Face1Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face2Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face3Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face4Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <Face5Svg class="fill-current" />
                  </v-btn>
                  <v-btn>
                    <FaceNaSvg class="fill-current" />
                  </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <div class="w-full grid grid-cols-4 mb-10 items-top">
         <div class="col-span-2">
             <span class="text-base">
                 Do you have any comments about making an appointment?
             </span>
         </div>
         <div class="col-span-2">
            <v-textarea
              outlined
              value="Enter text"
            ></v-textarea>
         </div>
        </div>
        
        <div class="grid w-full justify-items-end mt-8">
            <v-btn
                depressed
                class="success"
                @click="goToNextForm"
            >
                Submit Feedback Survey
            </v-btn>
        </div>
    </div>
    <!--END NEW HTML-->


    <p><br/>Thankyou Desktop below</p>
    <!--     Thankyou Desktop HTML-->
    <div class="m-auto max-w-1250 bg-white-pure l-box-shadow rounded py-8 px-10 text-left">
        <h2
            class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
        >
            <!--inside '' is category text-->
            Thank you for your feedback on 'My Appointment'
        </h2>

        <img 
            src="@/assets/images/thankyou_survey.png"
            alt="Thank you" class="w-full max-w-400 m-auto mt-8"
        />

        <div class="text-black mt-4 text-center mb-8">To provide feedback on other areas of the practice click continue (this questionnaire will take less than 10 mins).</div>

        <div class="grid w-full justify-items-end mt-4">
            <v-btn
                depressed
                class="success w-130"
                @click="goToNextForm"
            >
                Continue <fa-icon icon="chevron-right" class="ml-2 text-white-pure" />
            </v-btn>
        </div>
    </div>
    <!--     End Survey Desktop HTML-->


    <!-- OLD HTML below-->
    <!--
        <div class="w-full bg-white-pure l-box-shadow rounded px-8 py-6 text-center">
            <h2
                class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
            >Making an appointment and waiting to see a clinician at your last visit</h2>hello
            <div class="text-grey-2 text-sm mt-5 mb-2">Please rate each statement.</div>
            <RadioGroup
                ref="answer1"
                class="col-start-2"
                :label="'1. Seeing the clinician of your choice'"
                v-model="form.answer1"
            />
            <RadioGroup
                ref="answer2"
                class="col-start-2"
                :label="'2. Getting an appointment for a time that suited you'"
                v-model="form.answer2"
            />
            <RadioGroup
                ref="answer3"
                class="col-start-2"
                :label="'3. The time you had to wait to get this appointment (before getting to the clinic)'"
                v-model="form.answer3"
            />
            <RadioGroup
                ref="answer4"
                class="col-start-2"
                :label="'4. The time you had to wait after you arrived at the clinic'"
                v-model="form.answer4"
            />
            <RadioGroup
                ref="answer5"
                class="col-start-2"
                :label="'5. The amount of time spent travelling to the clinic'"
                v-model="form.answer5"
            />
            <CompleteFullSurvey
                ref="completeFullSurvey"
                v-model="form"
            />
            <div class="flex flex-row justify-center mt-8">
                <v-btn 
                    depressed
                    class="success"
                    :disabled="disabled"
                    @click="save"
                >{{buttonText}}</v-btn>
            </div>
        </div>
    -->

    </v-form>
</template>

<script>
import RadioGroup from '@/components/basic/RadioGroup'
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import {FormMixins} from '@/components/shared/mixins/formSave'
import {FormRules} from '@/components/shared/mixins/formMixins'
import Face1Svg from '@/assets/icons/faces/icon_face_1.svg'
import Face2Svg from '@/assets/icons/faces/icon_face_2.svg'
import Face3Svg from '@/assets/icons/faces/icon_face_3.svg'
import Face4Svg from '@/assets/icons/faces/icon_face_4.svg'
import Face5Svg from '@/assets/icons/faces/icon_face_5.svg'
import FaceNaSvg from '@/assets/icons/faces/icon_face_na.svg'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import {computed} from '@vue/composition-api'

export default {
    name: 'Form',
    // eslint-disable-next-line vue/no-unused-components
    components: {RadioGroup, CompleteFullSurvey, Face1Svg, Face2Svg, Face3Svg, Face4Svg, Face5Svg, FaceNaSvg},
    mixins: [FormMixins, FormRules],
    props: {
        formData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            tenantId: this.$route.query.tenantId,
            category: this.$route.query.category,
            change: this.$route.query.change,
            disabled: false,
            practiceName: this.$route.query.practiceName,
            isValid: true,
            defaultForm: {
                practiceName: '',
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: '',
                answer5: '',
                mobile: '',
                email: ''
            },
        }
    },
    mounted() {
        this.form.practiceName = this.$route.params.practiceName
        this.clearValidate()
    },
    methods: {
        changeValueHandler(event) {
            console.log('event', event)
        },
        validate() {
            this.isValid = this.$refs.form.validate()
            const invalidList = []
            for (let i = 1; i < 6; i++) {
                invalidList.push(this.$refs[`answer${i}`].validate())
            }
            invalidList.push(this.$refs.completeFullSurvey.validate())
            invalidList.push(this.isValid)
            return !invalidList.includes(false)
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
    },
    computed: {
        form() {
            return this.formData || this.defaultForm
        }
    },
    setup() {
        const tenantStylesStore = useTenantStylesStore()
        const { defaultSubmitButtonText } = storeToRefs(tenantStylesStore)

        const buttonText = computed(() => defaultSubmitButtonText.value || 'Submit feedback survey')

        return { buttonText }
    }
}
</script>
