<script>
import MobileLayout from '@/components/forms/mobile/Form'
import DesktopLayout from '@/components/forms/web/Form'
import Vue from 'vue'

//TODO: change this to swap between the Mobile and Web version of the dynamic form.
// Just putting a note here since the form needs to be loaded 1 level above and passed through here
export default {
    functional: true,
    name: "SwitchLayout",
    render(createElement, context) {
        // can be removed AFTER adding in code to pass through the list of questions
        if (Vue.$device.mobile) {
            // TODO: add back mobile site when it's ready
            return createElement(MobileLayout, context.data, context.children)
            // return createElement(ComingSoonLayout, context.data, context.children)
        }
        return createElement(DesktopLayout, context.data, context.children)
    }
}
</script>
