<template lang="pug">
    .m-auto.max-w-1250.bg-white-pure.l-box-shadow.rounded.py-8.px-10.text-left.h-screen
        .ls-xxs(v-if="!loading")
            //LogoTitleUltimate
            h2(class="text-center text-xl lg:text-2xl max-w-3/4 m-auto mt-4 mb-2")
                | Thank you!
            .text-black.text-center.mb-4(class="max-w-1/3 m-auto")
                | We love your feedback and will use it to create a better experience for you and everyone else.
            .text-center.font-weight-bold.mb-4
                | Do you have time for a few extra questions?
            .text-center(class="max-w-2/5 m-auto")
                | Please leave your email address or mobile number or
            .text-center.mb-8(class="max-w-2/5 m-auto")
                | scan the QR code and we'll send you a few follow up questions.
            .flex.flex-column.align-center
                .p-2.bg-white-pure.mb-2
                    QrcodeVue(
                        class="justify-center"
                        :value="linkToForm"
                        render-as="svg"
                        size="150"
                    )
            .mt-4.text-center.mb-8
                SendTextAndEmailForm(
                    ref="completeSurveyForm"
                    :is-kiosk="isKiosk"
                    :complete-survey-title="completeSurveyTitle"
                    :complete-survey-text="completeSurveyText"
                    v-model="completeSurvey"
                    @fieldFocus="fieldResetTimeout"
                )
            .flex.flex-row.justify-end.mt-8(v-if="showAlert" class="max-w-2/5 m-auto")
                v-alert(
                    :closable="false"
                    :type="alertType"
                    class="text-sm w-full"
                ) {{ message }}
            .flex.flex-row.justify-center.mt-8
                v-btn(
                    depressed
                    class="w-150 h-40"
                    @click="callBackToPreSurvey"
                )
                    | {{ finishButtonText }}
                v-btn(
                    v-if="!finished"
                    depressed
                    class="ml-4 success w-150 h-40"
                    @click="doSubmit"
                )
                    fa-icon(v-if="sendingEmail" icon="spinner" spin class="mr-2 text-white-pure")
                    | Send me a link
            .text-black.mt-4.text-center.mb-8.m-auto.text-sm(class="max-w-1/2")
                div
                    | All information you provide is
                    span.font-weight-bold.px-1
                        | anonymous and confidential
                div
                    | (including your email address and phone number).
                div.flex.flex-row.justify-center
                    div.pr-1
                        | For more details please see our
                    div.link-text
                        a(:href="privacyPolicyLink" )
                            | privacy policy
                    |.
        .text-black.mt-4.text-center.mb-8(v-else)
            Loader.w-12.h-12

</template>

<script>
import Loader from '@/assets/loader.svg'
import {computed, onMounted, onUnmounted, ref, watchEffect} from '@vue/composition-api'
import { NPS_COMPLETE_SURVEY_TEXT } from '@/modules/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {useTimeout} from '@/composables/thank-you'
import {useLazyQuery, useMutation} from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import SendTextAndEmailForm from '@/components/shared/SendTextAndEmailForm'
import LogoTitleUltimate from '@/components/basic/LogoTitleUltimate'
import QrcodeVue from 'qrcode.vue'
import {GET_FORM_SET} from '@/graphql/queries/formSetQueries.gql'
import {SEND_NPS_5Q_FORM} from '@/graphql/mutations/formFunctions.gql'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'NpsThankYouWithQrCode',
    components: {LogoTitleUltimate, Loader, SendTextAndEmailForm, QrcodeVue},
    props: {
        category: {
            type: String,
            default: ''
        },
        hasPhoneOrEmail: {
            type: Boolean,
            default: false
        },
        isFullSurvey: {
            type: Boolean,
            default: false
        },
        tenantName: {
            type: String,
            default: ''
        },
        // loading: {
        //     type: Boolean,
        //     default: false
        // }
    },
    setup(props, { root }) {
        const route = root.$route
        const category = ref(props.category)
        const shortNameRoute = ref(route.params.shortName)
        const showPhoneAndEmail = ref(!!route.query.spe)
        const routeType = ref(route.params?.routeType || '')
        const completeSurvey = ref({ email: '', mobile: { phone: '' } })
        const formAnswerId = ref(route.params?.formAnswerId)
        const tenantId = ref(route.query?.tenant || route.query?.ti)
        const formSetId = ref(route.query?.fsi)
        const loading = ref(true)
        const variables = ref({})
        const showAlert = ref(false)
        const message = ref('')
        const finished = ref(false)
        const sendingEmail = ref(false)
        const alertType = ref('success')
        const sendNps5Q = ref({
            sendNps5QId: route.query.ni,
            email: '',
            phone: '',
        })

        const linkedFormSetId = ref(null)
        const completeSurveyTitle = ref('Can we help you further?')
        const completeSurveyText = ref(NPS_COMPLETE_SURVEY_TEXT)
        const privacyPolicyLink = ref('https://google.com')
        //for now, hardcoding doctor
        const linkToForm = computed(() => {
            //TODO: make the below ks value generic
            return `${location.origin}/${shortNameRoute.value}/DynamicForm?referrer=nps&ks=DOCTOR&fsi=${linkedFormSetId.value}&fai=${formAnswerId.value}`
        })
        const {
            thankYouRedirectQueryParams,
            previousRoute: previous,
            tenant
        } = useGetters(['thankYouRedirectQueryParams', 'previousRoute', 'tenant'])
        const {
            isKiosk,
            previousRoute,
            params,
            shortName,
            router,
            completeSurveyForm,
            counter,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            fieldResetTimeout,
            redirectTo
        } = useTimeout(variables)
        previousRoute.value = previous.value || `${routeType.value}-view`
        if (isKiosk.value) previousRoute.value = `Kiosk-${previousRoute.value}`

        isKiosk.value = !!route.params.kiosk
        params.value = thankYouRedirectQueryParams.value
        shortName.value = shortNameRoute.value
        router.value = root.$router

        const {
            onError: sendNps5QOnError,
            mutate: sendNps5QMutate,
            onDone: sendNps5QOnDone
        } = useMutation(SEND_NPS_5Q_FORM, () => {
            return {
                variables: { ...sendNps5Q.value }
            }
        })

        const tenantIdOrCompare = [{
            tenant_id: {_eq: tenantId.value}
        }]

        if (tenant.value?.parentTenantId) {
            tenantIdOrCompare.push({
                tenant_id: {_eq: tenant.value?.parentTenantId}
            })
        }

        const {
            onResult: getFormSetOnResult,
            onError: getFormSetOnError,
            load: getFormSetLoad
        } = useLazyQuery(
            GET_FORM_SET, {
                formSetId: formSetId.value,
                tenantIdOrCompare
            }
        )
        //#region Get Form Set By Id
        // getFormSetOnResult(({ data }) => {
        getFormSetOnResult(({data }) => {
            if (data) {
                linkedFormSetId.value = camelcaseKeys(data || {}, {deep: true})?.formSetObj[0]?.linkedFormSetId
                loading.value = false
            }
        })

        getFormSetOnError((err) => {
            console.log('err from load', err)
            loading.value = false
        })
        //#endregion

        //#region functions
        const doSubmit = async () => {

            completeSurveyForm.value.clearValidate()
            cancelTimeout()
            if (!completeSurvey.value?.email && !completeSurvey.value?.mobile?.phone) {
                callBackToPreSurvey()
            } else {
                if (!completeSurveyForm.value?.validate()) {
                    alertType.value = 'error'
                    message.value = ``
                    return
                }
                try {
                    sendingEmail.value = true
                    sendNps5Q.value.shortName = shortName.value
                    sendNps5Q.value.fullName = completeSurvey.value.name
                    sendNps5Q.value.email = completeSurvey.value.email
                    sendNps5Q.value.phone = completeSurvey.value.mobile?.phone
                    sendNps5Q.value.formLink = linkToForm.value
                    const result = await sendNps5QMutate()
                    console.log('result', result)
                } catch (e) {
                    console.log('error', e)
                    alertType.value = 'error'
                    message.value = `Error sending survey to ${completeSurvey.value.email}`
                } finally {
                    if (message.value) showAlert.value = true
                    sendingEmail.value = false
                    runTimeout()
                }
            }
        }
        //#endregion

        //#region add sendNps5Q
        sendNps5QOnDone(({ data }) => {
            //now we need to run the thankyou
            console.log('data', data)
            // sendNps5Q.value = {...camelcaseKeys(newNotification || {}, {deep: true}) }
            alertType.value = 'success'
            message.value = `Thank you, for taking the time to fill out this survey.`
            finished.value = true
        })

        sendNps5QOnError((err) => {
            console.log('could not add the sendNps5Q', err)
            alertType.value = 'error'
            message.value = `Uh-oh, couldn't send the survey, please try again in a few minutes.`
            finished.value = false
        })
        //#endregion

        //#region computed properties
        const finishButtonText = computed(() => finished.value ? 'Go back' : 'No Thanks')
        const feedbackText = computed(() => {
            return category.value ? `on ${category.value}` : ''
        })

        const btnText = computed(() => {
            return isKiosk.value ? sendingEmail.value ?
                    'Please wait...' :
                    (completeSurvey.value.email || completeSurvey.value.mobile.phone) ?
                        `Send ` + (counter.value ? `(${counter.value})` : '') :
                        `Finish ` + (counter.value ? `(${counter.value})` : '') :
                'Finish'
        })

        //#endregion

        if (isKiosk.value) runTimeout()

        watchEffect(() => {
            sendNps5Q.value.email = completeSurvey.value.email
            sendNps5Q.value.phone = completeSurvey.value.phone
        })

        //#region life cycle hooks
        onMounted(async () => {
            // if we don't have a formAnswerId, we redirect back to the start
            if (!formAnswerId.value) await router.value.push({name: previousRoute.value})
            redirectTo.value = route?.query?.redirectTo
            await getFormSetLoad()
        })

        onUnmounted(() => {
            // make sure we get rid of the timeout if we come back through this page (browser back button pressed)
            cancelTimeout()
        })
        //#endregion

        return {
            loading,
            feedbackText,
            isKiosk,
            showPhoneAndEmail,
            completeSurvey,
            completeSurveyForm,
            btnText,
            showAlert,
            message,
            finished,
            alertType,
            sendingEmail,
            counter,
            completeSurveyTitle,
            completeSurveyText,
            privacyPolicyLink,
            linkToForm,
            finishButtonText,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            doSubmit,
            fieldResetTimeout,
        }
    }
}
</script>
