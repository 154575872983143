<template>
    <v-form
        v-model="isValid"
        ref="form"
        @submit.prevent
    >

    <!--Survey Mobile HTML -->
    <div class="w-full bg-white-pure l-box-shadow rounded p-4">
        <div class="max-w-screen-sm m-auto justify-center">
            <h2
                class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
            >
                <!--category text-->
                My Appointment
            </h2>
            <div class="text-grey-2 mt-2 text-center mb-8">Please rate each statement.</div>

            <div class="w-full mb-10">
                <div class="text-base mb-3">
                    I was able to make an appointment for a time that suited me
                </div>
                <div class="justify-center">
                    <v-btn-toggle
                        borderless
                        :ripple="false"
                        class="face-scale">
                      <v-btn>
                        <Face1Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face2Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face3Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face4Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face5Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <FaceNaSvg class="fill-current" />
                      </v-btn>
                    </v-btn-toggle>
                </div>
            </div>
 
            <div class="w-full mb-10">
                <div class="text-base mb-3">
                    I was able to see the doctor of my choice
                </div>
                <div class="justify-center">
                    <v-btn-toggle
                        borderless
                        :ripple="false"
                        class="face-scale">
                      <v-btn>
                        <Face1Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face2Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face3Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face4Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <Face5Svg class="fill-current" />
                      </v-btn>
                      <v-btn>
                        <FaceNaSvg class="fill-current" />
                      </v-btn>
                    </v-btn-toggle>
                </div>
            </div>
            <div class="w-full mb-0">
                <div class="text-base mb-3">
                     Do you have any comments about making an appointment?
                </div>
                 <div>
                    <v-textarea
                      outlined
                      value="Enter text"
                    ></v-textarea>
                 </div>
            </div>
            <div class="grid w-full justify-center">
                <v-btn
                    depressed
                    class="success"
                    @click="goToNextForm"
                >
                    Submit Feedback Survey
                </v-btn>
            </div>
        </div>
    </div>
    <!--END NEW HTML-->


    <!--Thankyou Mobile HTML -->
    <div class="w-full bg-white-pure l-box-shadow rounded p-4">
        <div class="max-w-screen-sm m-auto justify-center">
            <h2
                class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
            >
                <!--inside '' is category text-->
                Thank you for your feedback on 'My Appointment'
            </h2>

            <img 
                src="@/assets/images/thankyou_survey.png"
                alt="Thank you" class="w-full max-w-400 m-auto mt-8"
            />
            <div class="text-black mt-4 text-center mb-8">To provide feedback on other areas of the practice click continue (this questionnaire will take less than 10 mins).</div>

            <div class="grid w-full justify-center mt-8">
                <v-btn
                    depressed
                    class="success w-130"
                    @click="goToNextForm"
                >
                    Continue <fa-icon icon="chevron-right" class="ml-2 text-white-pure" />
                </v-btn>
            </div>

        </div>
    </div>
    <!--END NEW HTML-->




    <!-- OLD HTML below-->
    <!--
        <div class="w-full bg-white-pure l-box-shadow rounded p-4 text-left">
            <div class="w-full">
                <v-text-field
                    label="Practice name"
                    outlined dense
                    :disabled="true"
                    v-model="form.practiceName"
                ></v-text-field>
            </div>
            <h2
                class="text-lg mt-2 font-display"
            >Making an appointment and waiting to see a clinician at your last visit</h2>
            <div class="text-grey-2 text-sm mt-5 mb-2">Please rate each statement.</div>

            <label class="text-black block mt-5 mb-2">1. Seeing the clinician of your choice</label>
            <v-select
                outlined dense
                placeholder="Select rating"
                v-model="form.answer1"
                :items="dropdownOptions"
                :rules="[required]"
            ></v-select>
            <label class="text-black block mt-5 mb-2">2. Getting an appointment for a time that suited you</label>
            <v-select
                outlined dense
                placeholder="Select rating"
                v-model="form.answer2"
                :items="dropdownOptions"
                :rules="[required]"
            ></v-select>
            <label class="text-black block mt-5 mb-2">3. The timem you had to wait to get this appointment (before getting to the clinic)</label>
            <v-select
                outlined dense
                placeholder="Select rating"
                v-model="form.answer3"
                :items="dropdownOptions"
                :rules="[required]"
            ></v-select>
            <label class="text-black block mt-5 mb-2">4. The time you had to wait after you arrived at the clinic</label>
            <v-select
                outlined dense
                placeholder="Select rating"
                v-model="form.answer4"
                :items="dropdownOptions"
                :rules="[required]"
            ></v-select>
            <label class="text-black block mt-5 mb-2">5. The amount of time spent travelling to the clinic</label>
            <v-select
                outlined dense
                placeholder="Select rating"
                v-model="form.answer5"
                :items="dropdownOptions"
                :rules="[required]"
            ></v-select>
            <CompleteFullSurvey
                ref="completeFullSurvey"
                v-model="form"
            />
            <div class="flex flex-row justify-center mt-8">
                <v-btn 
                    depressed
                    class="success"
                    @click="save()"
                >{{buttonText}}</v-btn>
            </div>
        </div>
    -->

    </v-form>
</template>

<script>
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {FormMixins} from '@/components/shared/mixins/formSave'
import Face1Svg from '@/assets/icons/faces/icon_face_1.svg'
import Face2Svg from '@/assets/icons/faces/icon_face_2.svg'
import Face3Svg from '@/assets/icons/faces/icon_face_3.svg'
import Face4Svg from '@/assets/icons/faces/icon_face_4.svg'
import Face5Svg from '@/assets/icons/faces/icon_face_5.svg'
import FaceNaSvg from '@/assets/icons/faces/icon_face_na.svg'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import {computed} from '@vue/composition-api'


export default {
    name: 'Form',
    // eslint-disable-next-line vue/no-unused-components
    components: {CompleteFullSurvey, Face1Svg, Face2Svg, Face3Svg, Face4Svg, Face5Svg, FaceNaSvg},
    mixins: [FormRules, FormMixins],
    props: {
        formData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            tenantId: this.$route.query.tenantId,
            category: this.$route.query.category,
            change: this.$route.query.change,
            disabled: false,
            practiceName: this.$route.query.practiceName,
            isValid: true,
            defaultForm: {
                practiceName: '',
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: '',
                answer5: '',
                mobile: '',
                email: ''
            },
            dropdownOptions: [
                {text: 'Poor', value: 1},
                {text: 'Fair', value: 2},
                {text: 'Good', value: 3},
                {text: 'Very Good', value: 4},
                {text: 'Excellent', value: 5},
            ]
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            const invalidList = [
                this.$refs.completeFullSurvey.validate()
            ]
            invalidList.push(this.isValid)
            return !invalidList.includes(false)
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
    },
    computed: {
        form() {
            return this.formData || this.defaultForm
        }
    },
    setup() {
        const tenantStylesStore = useTenantStylesStore()
        const { defaultSubmitButtonText } = storeToRefs(tenantStylesStore)

        const buttonText = computed(() => defaultSubmitButtonText.value || 'Submit feedback survey')

        return { buttonText }
    }
}
</script>
