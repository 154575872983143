import gql from 'graphql-tag'

const UPSERT_NOTIFICATION = gql`
    mutation MyMutation(
        $notification: form_builder_hasura_notification_insert_input = {
            email: "",
            phone_number: "",
            tenant_id: "",
        },
        $on_conflict: form_builder_hasura_notification_on_conflict = {
            constraint: notification_pkey,
            update_columns: [email, phone_number]
        }
    ) {
        notification: insert_form_builder_hasura_notification_one(
            object: $notification,
            on_conflict: $on_conflict
        ) {
            notification_id
            form_answer_id
            email
            created_at
            phone_number
            tenant_id
        }
    }

`

export {
    UPSERT_NOTIFICATION
}
