<template>
    <v-form
        class="send-text-form"
        v-if="!readOnly"
        v-model="isValid"
        ref="formRef"
        @submit.prevent=""
    >
        <div :class="contactClass">
            <div class="grid grid-cols-1 gap-4">
                <v-text-field
                    ref="name"
                    hide-details outlined flat solo dense
                    :class="{'col-span-2': isMobile, 'disabled pointer-events-none': disabled}"
                    label="Name"
                    :rules="[required]"
                    v-model="form.name"
                    @focus="fieldFocus"
                    @blur="fieldBlur"
                ></v-text-field>
                <PhoneNumber
                    :class="{'col-span-2': isMobile, 'disabled pointer-events-none': disabled}"
                    class="nps-phone"
                    label="Mobile"
                    v-model="form.mobile"
                    :required="false"
                    @focus="fieldFocus"
                    @blur="fieldBlur"
                />
                <v-text-field
                    ref="email"
                    hide-details outlined flat solo dense
                    :class="{'col-span-2': isMobile, 'disabled pointer-events-none': disabled}"
                    label="Email"
                    v-model="form.email"
                    :rules="[validEmail]"
                    @focus="fieldFocus"
                    @blur="fieldBlur"
                ></v-text-field>
            </div>
        </div>
    </v-form>
</template>

<script>
import PhoneNumber from '@/components/basic/PhoneNumber'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {ref, watch, computed, onMounted} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'SendTextAndEmailForm',
    components: {PhoneNumber},
    mixins: [FormRules],
    emits: ['fieldFocus', 'fieldBlur'],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isKiosk: {
            type: Boolean,
            default: false
        },
    },
    setup(props, {root, emit}) {
        const device = root.$device
        const isValid = ref(true)
        const defaultForm = ref({})
        const formRef = ref({})
        const form = ref({})

        //#region computed properties
        const { readOnly } = useGetters(['readOnly'])
        const contactClass = computed(() => device.mobile ? 'max-w-screen-sm' : 'flex items-center justify-center')
        const isMobile = computed(() => device.mobile)
        // const form = computed(() => props.value)
        //#endregion

        const validate = () => {
            isValid.value = formRef.value.validate()
            return isValid.value
        }
        const clearValidate = () => {
            isValid.value = false
            return isValid.value
        }
        const fieldBlur = () => {
            emit('fieldBlur')
        }
        const fieldFocus = () => {
            emit('fieldFocus')
        }

        onMounted(() => {
            form.value = {...props.value}
        })

        watch(form, (newValue) => {
            emit('input', {...newValue})
        }, { deep: true })

        return {
            isValid,
            defaultForm,
            form,
            formRef,
            contactClass,
            readOnly,
            isMobile,
            validate,
            clearValidate,
            fieldBlur,
            fieldFocus,
        }
    }
}
</script>
