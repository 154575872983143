<template lang="pug">
    .m-auto.max-w-1250.bg-white-pure.l-box-shadow.rounded.py-8.px-10.text-left
        div(v-if="!loading")
            h2(class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4")
                | Thank you!
            div(class="text-center text-sm lg:text-lg max-w-3/4 m-auto mt-2") {{thankYouTitle}}
            .text-black.mt-4.text-center.mb-8(class="max-w-1/2 m-auto")
                | {{ thankYouText }}
            .mt-4.text-center.mb-8(v-if="showPhoneAndEmail")
                CompleteFullSurvey(
                    ref="completeSurveyForm"
                    :is-kiosk="isKiosk"
                    :complete-survey-title="completeSurveyTitle"
                    :complete-survey-text="completeSurveyText"
                    v-model="completeSurvey"
                    @fieldFocus="fieldResetTimeout"
                )
            .flex.flex-row.justify-end.mt-8(v-if="showAlert")
                v-alert(
                    :closable="false"
                    :type="alertType"
                    class="text-sm w-full"
                ) {{ message }}
            .flex.flex-row.justify-end.mt-8
                v-btn(
                    depressed
                    class="w-130"
                    @click="callBackToPreSurvey()"
                )
                    fa-icon(v-if="sendingEmail" icon="spinner" spin class="mr-2 text-white-pure")
                    | {{cancelBtnText}}
                    fa-icon(v-if="!sendingEmail" icon="chevron-right" class="ml-2 text-white-pure pl-1")
                v-btn(
                    v-if="!finished"
                    depressed
                    class="ml-4 success w-130"
                    @click="doSubmit()"
                )
                    | {{btnText}}
                    fa-icon(icon="chevron-right" class="ml-2 text-white-pure pl-1")
        .text-black.mt-4.text-center.mb-8(v-else)
            Loader.w-12.h-12

</template>

<script>
import Loader from '@/assets/loader.svg'
import {computed, onMounted, onUnmounted, ref, watchEffect} from '@vue/composition-api'
import {
    NPS_COMPLETE_SURVEY_TEXT, NPS_SURVEY_NO_PHONE_TEXT, NPS_SURVEY_TEXT,
} from '@/modules/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import {useTimeout} from '@/composables/thank-you'
import {useMutation} from '@vue/apollo-composable'
import {UPSERT_NOTIFICATION} from '@/graphql/mutations/notificationFunctions.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'NpsThankYou',
    components: {Loader, CompleteFullSurvey},
    props: {
        category: {
            type: String,
            default: ''
        },
        hasPhoneOrEmail: {
            type: Boolean,
            default: false
        },
        isFullSurvey: {
            type: Boolean,
            default: false
        },
        tenantName: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root }) {
        const route = root.$route
        const category = ref(props.category)
        const shortNameRoute = ref(!!route.params.shortName)
        const showPhoneAndEmail = ref(!!route.query.spe)
        const routeType = ref(route.params?.routeType || '')
        const completeSurvey = ref({ email: '', mobile: { phone: '' } })
        const formAnswerId = ref(route.params?.formAnswerId)
        const tenantId = ref(route.query?.tenant || route.query?.ti)
        const variables = ref({})
        const showAlert = ref(false)
        const message = ref('')
        const finished = ref(false)
        const sendingEmail = ref(false)
        const alertType = ref('success')

        const notification = ref({
            notificationId: route.query.ni,
            email: '',
            phoneNumber: '',
            tenantId: tenantId.value
        })
        const completeSurveyTitle = ref('Can we help you further?')
        const completeSurveyText = ref(NPS_COMPLETE_SURVEY_TEXT)
        const {
            thankYouRedirectQueryParams,
            previousRoute: previous
        } = useGetters(['thankYouRedirectQueryParams', 'previousRoute'])
        const {
            isKiosk,
            previousRoute,
            params,
            shortName,
            router,
            completeSurveyForm,
            counter,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            fieldResetTimeout,
            redirectTo
        } = useTimeout(variables)
        previousRoute.value = previous.value || `${routeType.value}-view`
        if (isKiosk.value) previousRoute.value = `Kiosk-${previousRoute.value}`

        isKiosk.value = !!route.params.kiosk
        params.value = thankYouRedirectQueryParams.value
        shortName.value = shortNameRoute.value
        router.value = root.$router

        const {
            onError: notificationOnError,
            mutate: notificationMutate,
            onDone: notificationOnDone
        } = useMutation(UPSERT_NOTIFICATION, () => {
            return {
                variables: {
                    notification: snakecaseKeys(notification.value)
                }
            }
        })

        //#region functions
        const doSubmit = async () => {
            cancelTimeout()
            if (!completeSurvey.value?.email && !completeSurvey.value?.mobile?.phone) {
                callBackToPreSurvey()
            } else {
                try {
                    sendingEmail.value = true
                    notification.value.email = completeSurvey.value.email
                    notification.value.phoneNumber = completeSurvey.value.mobile?.phone
                    const result = await notificationMutate()
                    console.log('result', result)
                    alertType.value = 'success'
                    message.value = notification.value.email || notification.value.phoneNumber ? `Thank you, someone will contact you soon.` : ''
                    finished.value = true
                } catch (e) {
                    console.log('error', e)
                    alertType.value = 'error'
                    message.value = `Error sending survey to ${completeSurvey.value.email}`
                } finally {
                    if (message.value) showAlert.value = true
                    sendingEmail.value = false
                    runTimeout()
                }
            }
        }
        //#endregion

        //#region add notification
        notificationOnDone(({ data: { notification: newNotification }}) => {
            //now we need to run the thankyou
            notification.value = {...camelcaseKeys(newNotification || {}, {deep: true}) }
        })

        notificationOnError((err) => {
            console.log('could not add the notification', err)
        })
        //#endregion

        //#region computed properties
        const feedbackText = computed(() => {
            return category.value ? `on ${category.value}` : ''
        })

        const btnText = computed(() => {
            return isKiosk.value ? sendingEmail.value ?
                    'Please wait...' :
                    (completeSurvey.value.email || completeSurvey.value.mobile.phone) ?
                        `Send ` + (counter.value ? `(${counter.value})` : '') :
                        `Finish ` + (counter.value ? `(${counter.value})` : '') :
                'Finish'
        })

        const cancelBtnText = computed(() => {
            return finished.value ? `Finish ` + (counter.value ? `(${counter.value})` : '')  : 'Cancel'
        })

        const thankYouTitle = computed(() => {
            if (!showPhoneAndEmail.value) return 'Thank you for your time.'
            return 'Can you tell us more?'
        })

        const thankYouText = computed(() => {
            if (showPhoneAndEmail.value) return NPS_SURVEY_TEXT
            return NPS_SURVEY_NO_PHONE_TEXT
        })

        //#endregion

        if (isKiosk.value) runTimeout()

        watchEffect(() => {
            variables.value = {
                email: completeSurvey.value.email,
                phone: completeSurvey.value.mobile?.phone,
                formAnswerId: formAnswerId.value,
                tenantId: tenantId.value
            }
        })

        //#region life cycle hooks
        onMounted(async () => {
            // if we don't have a formAnswerId, we redirect back to the start
            if (!formAnswerId.value) await router.value.push({name: previousRoute.value})
            redirectTo.value = route?.query?.redirectTo
        })

        onUnmounted(() => {
            // make sure we get rid of the timeout if we come back through this page (browser back button pressed)
            cancelTimeout()
        })
        //#endregion

        return {
            feedbackText,
            thankYouText,
            isKiosk,
            showPhoneAndEmail,
            completeSurvey,
            completeSurveyForm,
            btnText,
            showAlert,
            message,
            finished,
            cancelBtnText,
            alertType,
            sendingEmail,
            counter,
            completeSurveyTitle,
            completeSurveyText,
            thankYouTitle,
            callBackToPreSurvey,
            runTimeout,
            cancelTimeout,
            doSubmit,
            fieldResetTimeout,
        }
    }
}
</script>
