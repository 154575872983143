<template>
    <!-- TODO:change delay to 0-->
    <div
        style="width: 300px;"
        class="absolute inline-block top-0 right-0 bottom-0 pt-4 transition-all duration-500 delay-75 ease-out border-l-2 border-solid bg-white-pure"
    >
        <span class="p-4">
            <fa-icon icon="times" class="mr-1 text-xl group-hover:text-primary-dark" />
        </span>
        <SidebarContent :element="element" />
    </div>
</template>

<script>
import SidebarContent from '@/components/admin/partials/SidebarContent'
import {computed} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'Sidebar',
    components: { SidebarContent },
    setup() {
        const { elementToEdit } = useGetters(['elementToEdit'])

        const element = computed(() => elementToEdit.value?.element || {})

        return { element }
    }
}
</script>
